const service = [
  {
    name: '平台运营',
    intro: '整体规划、专业交付',
    icon: require('@/assets/images/home/s-01.jpg')
  },
  {
    name: '场景运营',
    intro: '业务驱动、目标直达',
    icon: require('@/assets/images/home/s-02.jpg')
  },
  {
    name: '客群运营',
    intro: '客群洞查、精细化运营',
    icon: require('@/assets/images/home/s-04.jpg')
  },
  {
    name: '优选权益',
    intro: '丰富资源，降本增效',
    icon: require('@/assets/images/home/s-03.jpg')
  }
  
 // {
 //   name: '智慧推广',
 //   intro: '大数据+AI+地推，革新推广方式',
 //   icon: require('@/assets/images/home/s-04.jpg')
 // }
]
  
export default service